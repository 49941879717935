/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&family=Roboto+Slab:wght@100&family=Roboto:wght@900&display=swap'); */
#root{
  height: 100vh;
  background: white;
}

.ant-layout.ant-layout-has-sider{
  height: 100%;
}

.ant-layout-content.site-layout-background{
  height: calc(100vh - 64px);
  /* overflow-y: scroll; */
  background: white;
  margin: 15px 30px;

}

.simple-react-table{
  width: 98%;
}

.ant-modal-body{
  padding: 0px 24px!important;
}

.ant-modal-body form{
  display: flex;
  flex-direction: column;
}

.ant-modal-body form label{
  color: #9e9e9e !important
}

.ant-modal-body form input{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
}

.ant-modal-body form textarea{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
}

.ant-modal-header{
  text-align: center;
}

.ant-input-wrapper.ant-input-group{
  width: 50%;
}

.submit-btn{
  margin: 20px;
  cursor: pointer;
  background: #001529!important;
  color: white!important;
  font-size: 18px;
}

.ant-upload-list.ant-upload-list-text
{
  display: none;
}